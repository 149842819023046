import React, { useState, useEffect } from 'react';
import { getRegister, deleteRegister } from './Service';
import AAQ from "../LoginPage/AAQ-1904.png";
import "./userlist.css";
import Login from '../LoginPage/login';
import Controls from './Controls';
import { Link } from 'react-router-dom';
import { Logout } from '@mui/icons-material';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RegisteredEmailsList = () => {
  // const [registeredEmails, setRegisteredEmails] = useState([]);
  // const [showControls, setShowControls] = useState(false);
  // const [showLogin, setShowLogin] = useState(false);
  // const notify = () => toast('User Deleted Successfully');


  // useEffect(() => {
  //   async function fetchRegisteredEmails() {
  //     try {
  //       const response = await getRegister();
  //       setRegisteredEmails(response.data);
  //     } catch (error) {
  //       console.error('Error fetching registered emails:', error);
  //     }
  //   }

  //   fetchRegisteredEmails();
  
  // }, []);

  // const handleDelete = async (email) => {
  //   try {
  //     await deleteRegister(email);
  //     setRegisteredEmails((emails) => emails.filter((e) => e !== email));
  //     notify();
  //   } catch (error) {
  //     console.error('Error deleting registered email:', error);
  //   }
  // };



  return (
    <div className='bgconatainer'>
    <h1>Hi</h1>
      {/* <div>
        <header className='header-1'>
          <div className='logo-container'>
            <img className='rhym-logo' src={AAQ} alt="logo" />
          </div>
          <div className='button-container'>
            <div className='btn-cnt-1'>
              <Logout />
              <Link className='link' to="/" onClick={() => setShowLogin(true)}>Logout</Link>
            </div>
          </div>
        </header>
      </div>
      <div className='list-container'>
        {showControls && <Controls />}
        <h1 className='head-1'>List of Registered Emails</h1>
        <div className="emails-container">
         
          {registeredEmails.map((email, index) => (
            <div key={index} className="email-item">
              <SentimentSatisfiedAltIcon style={{ fontSize: 35, marginRight: 10, color: '#7d2cf2' }} />
              {email}
              <div className='deletebtn-container'>
                <DeleteIcon style={{ fontSize: 20, marginLeft: 5, color: '#FF2E2E' }} onClick={() => handleDelete(email)}/>
              </div>
            </div>
          ))}
        </div>
        <button className='button-login control-btn '>
          <Link className='link' to="/ControlsPage">Back</Link>
        </button>
        {showLogin && <Login />} */}
      {/* </div>
       <ToastContainer/> */}
    </div>
  );
};

export default RegisteredEmailsList;
