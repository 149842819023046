import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EditControls from './EditControls';
import AddControls from './AddControls';
import './ControlPage.css';
import AAQ from '../LoginPage/AAQ-1904.png';
import Login from '../LoginPage/login';
import UserList from '../Components/userslist.js';
import { Logout } from '@mui/icons-material';
import PeopleIcon from '@mui/icons-material/People';
import { getStandards } from './Service';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import avatar from '../LoginPage/avatar.jpg';
import { useUser } from './UserContext.js';
import Slider from 'react-slick';
import VisibilityIcon from '@mui/icons-material/Visibility';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Controls = () => {
  const [showAddControls, setShowAddControls] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showUserList, setShowUserList] = useState(false);
  const [standards, setStandards] = useState([]);
  const { user } = useUser();

  useEffect(() => {
    async function fetchStandards() {
      try {
        const response = await getStandards();
        setStandards(response.data);
      } catch (error) {
        console.error('Error while fetching standards:', error);
      }
    }
    fetchStandards();
  }, []);

  const Carousel = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
    };
     if (standards.length === 0) {
      return <p>loading</p>;
     }
    return (
      <div className='slider-container'>
        <Slider {...settings} className="slider">
        {standards.map((standard, index) => (
          <div key={index} className="carousel-item">
            <button className="list-button">
              {standard} <EventNoteIcon className="event-note" />
            </button>
          </div>
        ))}
      </Slider>
      </div>
    );
  };

  return (
    <div className="bgconatainer">
      <header className="header-1">
        <div className="logo-container">
          <img className="rhym-logo" src={AAQ} alt="logo" />
        </div>
        <div className="button-container">
          <div className="avatar-container">
            <div>
              <img src={avatar} alt="avatar" className="avatar" />
            </div>
            <div>
              {user.email && <p className="p">Hello, {user.email}</p>}
            </div>
          </div>
          <div>
            <vr className="vr" />
          </div>
          <div className="btn-cnt-1">
            <PeopleIcon />
            <Link className="link" to="/userlist" onClick={() => setShowUserList(true)}>
              UserList
            </Link>
          </div>
          <div className="btn-cnt-1">
            <Logout />
            <Link className="link" to="/" onClick={() => setShowLogin(true)}>
              Logout
            </Link>
          </div>
        </div>
      </header>
      <div className="boom">
        <button className="button-login control-btn" onClick={() => setShowAddControls(!showAddControls)}>
          {showAddControls ? (
            <>
              View Standard <VisibilityIcon style={{ fontSize: 20, marginLeft: 5 }} />
            </>
          ) : (
            <>
              Add Standard <AddCircleOutlineIcon style={{ fontSize: 20, marginLeft: 5 }} />
            </>
          )}
        </button>

        <Carousel />

        {showAddControls ? <AddControls /> : <EditControls />}
        {showLogin && <Login />}
        {showUserList && <UserList />}
      </div>
    </div>
  );
};

export default Controls;
