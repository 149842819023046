// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Controls from './Components/Controls';
import UserList from './Components/userslist';
import { UserProvider } from './Components/UserContext';
import Login from './LoginPage/login';

function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/ControlsPage" element={<Controls/>} />
          <Route path="/userlist" element={<UserList/>} /> 
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;
